<template>
  <div v-if="src != ''">
    <v-row>
      <v-card
        v-if="src != '' && showImg == true"
        elevation="0"
        class="d-flex align-center justify-center mb-6"
      >
        <v-img
          :src="src"
          :lazy-src="src"
          max-height="200px"
          width="100%"
          position="top"
          class="mx-auto"
          aspect-ratio="1"
          cover
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ImgCover',
  props: {
    title:{
      type: String,
      default: "",
    },
    src: {
      type: String,
      default: "",
    },
  },
  computed: {
    showImg() {
      // console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
      return false;
    },
  },
};
</script>